.data-table {
    th {
      &.default:after {
        font-family: "Material Icons";
        content: "\e5d7";
        position: absolute;
        margin-left: 3px;
        color: #232f3e;

      }
      &.asc:after {
        font-family: "Material Icons";
        content: "\e5db";
        position: absolute;
        margin-left: 3px;
        color: #006170;
      }
      &.desc:after {
        font-family: "Material Icons";
        content: "\e5d8";
        position: absolute;
        margin-left: 3px;
        color: #006170;
      }
      &.sortable {
        cursor: pointer;
      }
    }
  }

  .orgFiltersSidenav {
    >li {
      list-style-type: none;
      display: block !important;
      >div {
        
      }
      >button, >a, li a {
        padding: 1em !important;
      }
      fieldset {
        width: 100%;
      }
    }
  }